import React from "react";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const FeedbackIcon = ({ wasSelected, isCorrect }) => {
  if (wasSelected) {
    return isCorrect ? (
      <CheckBoxIcon
        sx={(theme) => ({
          color: theme.palette.common.green,
        })}
      />
    ) : (
      <DisabledByDefaultIcon
        sx={(theme) => ({
          color: theme.palette.error.dark,
        })}
      />
    );
  }

  return <CheckBoxOutlineBlankIcon />;
};

export default FeedbackIcon;
