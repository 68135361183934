import React from "react";
import { Typography, Box } from "@mui/material";

const Welcome = ({ logo, name, customGreeting }) => (
  <Box sx={{ textAlign: "center", p: 2 }}>
    {logo ? (
      <>
        <Typography variant="h6" color="primary" gutterBottom>
          {customGreeting || "Quizzify Welcomes"}
        </Typography>
        <Box
          sx={{
            width: { xs: "80%", sm: 500 },
            maxWidth: "100%",
            height: 100,
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 1,
            pb: 1,
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </>
    ) : (
      <Typography variant="h6" color="primary">
        Welcome to Quizzify for {name}!
      </Typography>
    )}
  </Box>
);

export default Welcome;
