import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Button,
  Modal,
  IconButton,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import pluralize from "pluralize";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "100%",
  maxHeight: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const IncentiveStatusModal = ({ open, handleClose, contest }) => {
  if (!contest) return null;
  const { quiz_completions, target_quiz_completions } = contest;

  if (quiz_completions >= target_quiz_completions) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Card sx={modalStyle}>
          <CardHeader
            title="My Incentive Status"
            onClick={handleClose}
            action={
              <IconButton>
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <Typography>
              You have met the incentive requirements! But you are welcome to
              keep learning and reviewing.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <Button onClick={handleClose}>Close</Button>
          </CardActions>
        </Card>
      </Modal>
    );
  }

  if (quiz_completions < target_quiz_completions) {
    let difference = target_quiz_completions - quiz_completions;

    return (
      <Modal open={open} onClose={handleClose}>
        <Card sx={modalStyle}>
          <CardHeader
            title="My Incentive Status"
            onClick={handleClose}
            action={
              <IconButton>
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <Typography>
              Finish {difference} more {pluralize("quiz", difference)} to
              qualify!
            </Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <Button onClick={handleClose}>Close</Button>
          </CardActions>
        </Card>
      </Modal>
    );
  }

  return null;
};

export default IncentiveStatusModal;
