import React from "react";
import { Card, CardHeader, Divider, CardContent } from "@mui/material";
import HealthcareBluebookSignup from "../HealthcareBluebookSignup";

const PartnerVendors = ({ quiz = {} }) => {
  if (!quiz) return null;
  if (!quiz.endScreen) return null;

  if (!quiz.endScreen?.partner_vendors?.includes("healthcare-bluebook"))
    return null;

  return (
    <Card raised>
      <CardHeader title="Take Advantage of Your Other Benefits" />
      <Divider />
      <CardContent>
        <HealthcareBluebookSignup />
      </CardContent>
    </Card>
  );
};

export default PartnerVendors;
