import React from "react";
import { Container, Typography, Button, Stack } from "@mui/material";

const HealthcareBluebookSignup = () => (
  <Container maxWidth="xs">
    <Stack spacing={2}>
      <img
        src="/img/healthcare-blue-book-logo.png"
        alt="Healthcare Bluebook Logo"
      />
      <Typography variant="h6">Signup for Healthcare Bluebook!</Typography>
      <Typography>
        Take control of your healthcare costs with Healthcare Bluebook, the
        ultimate tool for comparing prices and quality of medical services in
        your area. Save money and ensure the best care for you and your family
        with easy-to-use insights at your fingertips!
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        href="https://www.healthcarebluebook.com/ui/home"
        target="_blank"
        rel="noopener noreferrer"
      >
        Create Your Account Now
      </Button>
    </Stack>
  </Container>
);

export default HealthcareBluebookSignup;
