import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Divider,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import FeedbackIcon from "./FeedbackIcon";
import { context as quizContext, actions } from "../../QuizContext";
import transform from "../../../../utils/transformLinks";

const TileAnswer = ({
  id,
  mainText,
  feedbackText,
  image,
  wasSelected,
  isCorrect,
  setMaxImageHeight,
  maxImageHeight,
}) => {
  const { dispatch } = useContext(quizContext);
  const [imageHeight, setImageHeight] = useState(0);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const containerWidth = event.target.parentElement.offsetWidth;

    const aspectRatio = naturalWidth / naturalHeight;
    const calculatedHeight =
      aspectRatio > 1 ? containerWidth / aspectRatio : containerWidth;

    setImageHeight(calculatedHeight);
  };

  useEffect(() => {
    if (imageHeight > 0) {
      setMaxImageHeight((prev) => Math.max(prev, imageHeight));
    }
  }, [imageHeight, setMaxImageHeight]);

  const cappedMaxHeight = Math.min(250, maxImageHeight || 250);

  return (
    <Card
      sx={{ cursor: "pointer" }}
      onClick={(_) =>
        dispatch({
          type: actions.selectAnswer,
          answerId: id,
        })
      }
    >
      {image && (
        <>
          <div
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              position: "relative",
              height: cappedMaxHeight,
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                objectPosition: "center",
                userDrag: "none",
              }}
              src={image.url}
              alt={image.alt}
              onLoad={handleImageLoad}
            />
          </div>
          <Divider />
        </>
      )}
      {mainText ? (
        <CardHeader
          title={
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {ReactHtmlParser(mainText, { transform })}
            </Typography>
          }
          action={
            <FeedbackIcon wasSelected={wasSelected} isCorrect={isCorrect} />
          }
        />
      ) : null}
      {feedbackText ? (
        <Collapse in={wasSelected} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="body1">
              {ReactHtmlParser(feedbackText, { transform })}
            </Typography>
          </CardContent>
        </Collapse>
      ) : null}
    </Card>
  );
};

export default TileAnswer;
