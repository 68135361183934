import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import isQuestionComplete from "./isQuestionComplete";

const LearnMoreLink = ({ question, lang, format }) => {
  const { t } = useTranslation();

  const learnMoreText = question?.learnMoreText || "Learn More";

  if (!question.learnMoreUrl) return null;

  if (format === "after_question_complete" && !isQuestionComplete(question)) {
    return null;
  }

  return (
    <Button
      sx={{ mt: 1 }}
      href={question.learnMoreUrl}
      target="_blank"
      rel="noopener noreferrer"
      color="secondary"
      variant="contained"
    >
      {lang === "es" ? "Aprende más" : t(learnMoreText)}
    </Button>
  );
};

export default LearnMoreLink;
