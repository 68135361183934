import React from "react";
import {
  Container,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const Deactivated = ({ message }) => {
  const history = useHistory();

  const displayMessage =
    message ||
    history?.location?.state?.message ||
    "Quizzify service has been deactivated. Please contact HR for more info.";

  return (
    <Container maxWidth="sm" sx={{ pb: 2 }}>
      <Card
        raised
        sx={{
          mt: {
            xs: 2,
            sm: 2,
            md: 5,
            lg: 5,
            xl: 5,
          },
        }}
      >
        <CardHeader
          avatar={<SentimentVeryDissatisfiedIcon />}
          title="Service Deactivated"
        />
        <Divider />
        <CardContent>
          <Typography variant="subtitle1" sx={{ whiteSpace: "pre-wrap" }}>
            {ReactHtmlParser(displayMessage)}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Deactivated;
