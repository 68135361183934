import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Box,
  Stack,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import api from "../../api/endpoints";

const Q2GoButton = ({ companyId }) => {
  return (
    <Stack spacing={2}>
      <Card variant="outlined">
        <CardHeader
          avatar={<ExitToAppIcon />}
          title={
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Save at the ER
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <Stack spacing={3}>
            <Typography>
              Save our Apple or Google Wallet pass to your phone to keep most
              bills under $1,000.
            </Typography>

            <Box sx={{ pl: 5, pr: 5 }}>
              <img
                alt="Prevent Consent Preview"
                src="/img/prevent-consent-preview.png"
                style={{ maxWidth: "100%", maxHeight: 425 }}
              />
            </Box>

            <Button
              href={`/${companyId}/er`}
              variant="contained"
              color="secondary"
              onClick={() => {
                api.activity.logEvent({
                  event_category: "PREVENT_CONSENT_INTERACTION",
                  event_action:
                    "Clicked home page button: 'Get the ER Prevent Consent Pass'",
                });
              }}
            >
              Get the ER Prevent Consent Pass
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardHeader
          avatar={<ExitToAppIcon />}
          title={
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Prepare for Your Next Doctor Visit
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <Stack spacing={3}>
            <Typography>
              Choose from over 200 "Doctor Visit PrepKits" to prepare for your
              next clinical visit.
            </Typography>

            <Box sx={{ pl: 5, pr: 5, textAlign: "center" }}>
              <img
                alt="Prevent Consent Preview"
                src="/img/prepkit-preview-2.png"
                style={{ maxWidth: "100%", maxHeight: 350 }}
              />
            </Box>

            <Button href={"/prepkits"} variant="contained" color="secondary">
              Get Started
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default Q2GoButton;
