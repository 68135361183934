import React, { useState } from "react";
import { Grid } from "@mui/material";
import TileAnswer from "./TileAnswer";

const TileAnswers = ({ question }) => {
  const [maxImageHeight, setMaxImageHeight] = useState(0);

  const lg = (() => {
    switch (question.answers.length) {
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 3;
    }
  })();

  return (
    <Grid container spacing={2} sx={{ pt: 1.5, pb: 1.5 }}>
      {question.answers.map((answer) => (
        <Grid key={answer.id} item xs={12} sm={6} md={6} lg={lg}>
          <TileAnswer
            {...answer}
            setMaxImageHeight={setMaxImageHeight}
            maxImageHeight={maxImageHeight}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TileAnswers;
