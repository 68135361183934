import React from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Stack,
  Grid,
  Divider,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import keyMirror from "../../utils/keyMirror";
import ResponsiveContainer from "../ResponsiveContainer";
import CtaLink from "./CtaLink";
import SurveyForm from "./SurveyForm";
import LogoImage from "./LogoImage";
import CompletionMessage from "./CompletionMessage";

import Confetti from "./Confetti";
import ContactForm from "./ContactForm";
import ContestMessage from "./ContestMessage";

import FinalScore from "./FinalScore";
import LoginPrompt from "./LoginPrompt";
import MoreQuizzes from "./MoreQuizzes";
import NycCustom from "./NycCustom";
import SavePdf from "./SavePdf";
import RetakeQuiz from "./RetakeQuiz";
import ShareQuiz from "./ShareQuiz";
import Epilogue from "./Epilogue";
import SalesPitch from "./SalesPitch";
import PartnerVendors from "./PartnerVendors";
import homePageUrl from "../../utils/homePageUrl";

const R = require("ramda");

const { ctaLink, contactForm, contestMessage, survey } = keyMirror({
  ctaLink: null,
  contactForm: null,
  contestMessage: null,
  survey: null,
});

const typeLens = R.lensProp("type");
const isType = (type) => R.pipe(R.view(typeLens), R.equals(type));

const CardContainer = ({ children, title }) => (
  <Card raised>
    {title && (
      <>
        <CardHeader title={title} />
        <Divider />
      </>
    )}
    <CardContent>{children}</CardContent>
  </Card>
);

const createSection = (state) =>
  R.cond([
    [
      isType(ctaLink),
      (s) => (
        <CardContainer key={s.type}>
          <CtaLink cta={s} />
        </CardContainer>
      ),
    ],
    [
      isType(contactForm),
      (s) => (
        <CardContainer key={s.type}>
          <ContactForm contactForm={s} state={state} />
        </CardContainer>
      ),
    ],
    [
      isType(contestMessage),
      (s) => (
        <CardContainer key={s.type}>
          <ContestMessage contestMessage={s} />
        </CardContainer>
      ),
    ],
    [
      isType(survey),
      (s) => (
        <CardContainer key={s.type} title={"Feedback Survey (Optional)"}>
          <SurveyForm survey={s} state={state} />
        </CardContainer>
      ),
    ],
    [R.T, R.always(null)],
  ]);

// const showTimedOutMessage = (quiz) =>
//   quiz.endTime && moment().isAfter(quiz.endTime) && quiz.closedAfterEndTime;

//   {!showTimedOutMessage && <Confetti />}
//   {showTimedOutMessage && (
//               <Grid item xs={12} sm={12} className={classes.root}>
//                 <Typography color="error" variant="h6">
//                   Time's up for this one.
//                 </Typography>
//               </Grid>
//             )}
//                  {!showTimedOutMessage && (
//                       <Grid item>
//                         <RetakeQuiz />
//                       </Grid>
//                     )}

const SharedEndScreen = ({ state }) => {
  const { embedView } = state;

  return (
    <Box sx={{ height: "100%" }}>
      <Confetti />
      <ResponsiveContainer embedView={embedView} style={{ maxWidth: 750 }}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h5" gutterBottom>
                If you disliked this, you'll hate{" "}
                <Link
                  href="http://www.quizzify.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  quizzify.com
                </Link>
                !
              </Typography>
            }
          />
          <Divider />
          <CardContent>
            <Typography variant="subtitle1">
              Quizzify's engaging health literacy quizzes, reviewed by doctors
              at Harvard Medical School, provide education to employees on
              health, healthcare, and health benefits.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              onClick={() => window.open("http://www.quizzify.com", "_blank")}
              variant="contained"
              color="secondary"
            >
              Learn More About Quizzify
            </Button>
            <Button variant="outlined" onClick={() => window.location.reload()}>
              Replay Question
            </Button>
          </CardActions>
        </Card>
      </ResponsiveContainer>
    </Box>
  );
};

const EndScreen = ({ state }) => {
  const { t, i18n } = useTranslation();

  const isEnglish = i18n.currentLanguage === "en" || !i18n.currentLanguage;

  const history = useHistory();
  const endScreen = state.quiz?.endScreen;

  const redirectTo = endScreen?.redirect_to;
  const sectionsOrDefault = endScreen?.sections || [];

  const {
    company,
    score,
    quiz: { isShared, id: quizId, is_public: canShare },
  } = state;

  if (redirectTo) {
    history.push(`${redirectTo}?original_url=${window.location.href}`);
  }

  const width = isEnglish ? 850 : 700;

  return !isShared ? (
    <>
      <Confetti />
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <Stack
          spacing={2}
          sx={(theme) => ({
            maxWidth: "100%",
            width,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: {
              md: theme.spacing(4),
            },
            pb: 5,
          })}
        >
          <Card raised>
            <CardContent>
              <Stack
                spacing={2}
                sx={{ alignItems: "center", textAlign: "center" }}
              >
                <Grid container spacing={2}>
                  <LogoImage company={company} />

                  <Grid item xs={12} sm={6}>
                    <CompletionMessage {...state} />
                    <br />
                    <FinalScore
                      score={score}
                      usePoints={_.get(company, "use_points")}
                    />
                  </Grid>
                </Grid>

                <SalesPitch {...state} />

                <Epilogue {...state} />

                <LoginPrompt {...state} />
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ flexWrap: "wrap" }}>
              {/* Show home page only if homePageUrl provided as some companies do not */}
              {/* However, given the iframe approach keep the link a local pathname */}
              {homePageUrl(state) && company ? (
                <Button
                  onClick={() => {
                    const url = homePageUrl(state);
                    window.location.href = url;
                  }}
                  variant="outlined"
                  startIcon={<HomeIcon />}
                  sx={{ flexShrink: 0, mt: "4px", mb: "4px" }}
                >
                  {t("Home")}
                </Button>
              ) : null}
              <RetakeQuiz />

              {quizId && <SavePdf quizId={quizId} />}
              {isEnglish && canShare && <ShareQuiz quizId={quizId} />}
            </CardActions>
          </Card>

          <PartnerVendors {...state} />

          {isEnglish && (
            <>
              <MoreQuizzes {...state} />

              {company.id === 942 && <NycCustom />}
              {sectionsOrDefault.length > 0 && (
                <>{R.map(createSection(state), sectionsOrDefault)}</>
              )}
            </>
          )}
        </Stack>
      </Box>
    </>
  ) : (
    <SharedEndScreen state={state} />
  );
};

export default EndScreen;
