import React from "react";
import AnswerList from "./AnswerList";
import SliderAnswer from "./SliderAnswer";
import TileAnswers from "./TileAnswers";

const Answers = ({ question }) => {
  if (!question) {
    return null;
  }

  const { format } = question;

  if (format === "slider") {
    return <SliderAnswer question={question} />;
  }

  if (format === "tiles") {
    return <TileAnswers question={question} />;
  }

  return <AnswerList question={question} />;
};

export default Answers;
